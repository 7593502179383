/* Start General Style
************************/
%unselect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

html {
  overflow: initial !important;
}

body {
  background: url("../images/hero-bg.png") center center;
  background-attachment: fixed;

  font-family: 'Roboto', sans-serif;
  font-size: var(--base-font);
  color: #707070;
  padding-top: 111px;
  overflow-x: hidden;
  &.site-ltr {
    direction: ltr;
  }
  &.site-rtl {
    direction: rtl;
  }
  @include respond-below(xxxl) {
    padding-top: 95px;
  }
  @include respond-below(lg) {
    padding-top: 90px;
  }

  .tutor-style {
    --auth-main-color: #{$blue};
    --auth-main-color-hover: #{$blue-hover};
    .form--group .select2-selection__arrow:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15239' data-name='Path 15239' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }
    .dashboard--menu--item-link.link-collapse {
      &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15211' data-name='Path 15211' d='M592.995,120.1l-4.764-4.784a1.055,1.055,0,0,0-1.494,0L581.95,120.1a.969.969,0,0,0-.076,1.433l.175.178a.767.767,0,0,0,1.157.057q1.769-1.764,3.531-3.528a1.055,1.055,0,0,1,1.494,0q1.759,1.764,3.528,3.528a.764.764,0,0,0,1.154-.057l.181-.178a.962.962,0,0,0-.1-1.433Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A") !important;
      }
      &.collapsed:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15218' data-name='Path 15218' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%239f9f9f' fill-rule='evenodd'/%3E%3C/svg%3E%0A") !important;
      }
    }
    .header--nav-dropdown-link.show:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15231' data-name='Path 15231' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
    }
  }
}

.dir-ltr {
  direction: ltr !important;
}


.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  border: 1px solid #c6c6c5;
  border-radius: 2px;
}

.modal-header .btn-close {
  margin: 0 !important;
}

.btn-secondary {
  color: #FFF !important;
}

.modal-body {
  .tab-pane {
    margin-bottom: 20px;
    padding-bottom: 15px;
    &:not(:last-child) {
      border-bottom: 1px solid #DDD;
    }
    audio, video {
      width: 100%;
    }
  }
}

.dhx_cal_data div.dhx_cal_event_clear {
  color: #FFF;
}

#videos {
  video {
    width: 100%;
    margin: 1rem 0;
  }
}
#audios {
  audio {
    width: 100%;
    margin: 1rem 0;
  }
}

@include respond-above(xxl) {
  .container.container-large {
    max-width: 1320px;
  }
}
@include respond-above(xxxl) {
  .container.container-large {
    max-width: 1650px;
  }
}
/* End General Style
************************/


// bootstrap accordion
.accordion {
  margin-bottom: 15px;
  .accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(0,0,0,.125);
  }
  .accordion-button:not(.collapsed), .accordion-button:focus {
    box-shadow: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: #e7e7e785;
    color: inherit;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  .accordion-button::after {
    @include rtl {
      margin-right: auto;
      margin-left: unset;
    }
  }
}

// bootstrap checkbox
.form-check-inline {
  margin-bottom: 12px;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: 17px;
}