/* Start Pending Tutors
************************/
.pending-tutors {
  &--filter-box {
    padding: 20px 70px;
    margin-bottom: 20px;

    @include respond-below(xxxl) {
      padding: 20px 40px;
    }
    @include respond-below(sm) {
      padding: 20px;
    }
    &-title {
      color: #9F9F9F;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 10px;
    }
    &-content {
      display: flex;
      @include respond-below(xxl) {
        flex-wrap: wrap;
      }
      .form--group {
        margin: 0;
      }
    }
    &-form-group {
      display: flex;
      @include ltr() {
        margin-right: 40px;
      }
      @include rtl() {
        margin-left: 40px;
      }
      align-items: center;
      margin-bottom: 20px;
      @include respond-below(xxxl) {
        @include ltr() {
          margin-right: 10px;
        }
        @include rtl() {
          margin-left: 10px;
        }
      }
      &>label {
        font-size: 18px;
        color: #9F9F9F;
        @include ltr() {
          margin-right: 20px;
        }
        @include rtl() {
          margin-left: 20px;
        }
        @include respond-below(xxxl) {
          font-size: 16px;
          @include ltr() {
            margin-right: 10px;
          }
          @include rtl() {
            margin-left: 10px;
          }
        }
        @include respond-below(sm) {
          font-size: 14px;
        }
      }
      .form-check-label {
        @include ltr() {
          padding-right: 5px;
        }
        @include rtl() {
          padding-left: 5px;
        }
      }

      .check-box-container {
        display: inline-flex;
      }
      .check-box-container:not(:last-child) {
        @include ltr() {
          margin-right: 20px;
        }
        @include rtl() {
          margin-left: 20px;
        }
      }
      .select2-container {
        width: 200px !important;
        @include respond-below(xxxl) {
          width: 170px !important;
        }
      }
    }
    .submit-btn {
      max-width: 135px;
      width: 100%;
      height: fit-content;
    }
  }
  &--table-box {
    padding: 20px 70px;

    @include respond-below(xxxl) {
      padding: 20px 40px;
    }
    &-title-content {
      margin-bottom: 35px;
      text-align: center;
      color: #9F9F9F;
    }
    &-title {

      line-height: 1;
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    &-subtitle {
      line-height: 1;
      margin-bottom: 0;
      font-size: 16px;
    }
    .table-responsive {
      padding-bottom: 20px;
      @include respond-below(sm) {
        border-bottom: 0;
      }
      &::-webkit-scrollbar {
        width: 10px;
        @include respond-below(sm) {
          width: unset;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: #EEEEEE;
        border-radius: 10px;
        overflow: hidden;
        @include respond-below(sm) {
          background-color: unset;
          border-radius: unset;
        }
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(#9F9F9F, 0.5);
        border-radius: 10px;
        @include respond-below(sm) {
          background-color: unset;
          border-radius: unset;
        }
      }
    }
    &-table {
      th, td {
        font-size: 18px;
        color: #9F9F9F;
        font-weight: 400;
        text-align: center;
        @include respond-below(xxxl) {
          font-size: 14px;
        }
        &:not(:last-child) {
          @include ltr() {
            border-right: 0.5px solid var(--auth-main-color);
          }
          @include rtl() {
            border-left: 0.5px solid var(--auth-main-color);
          }
          @include respond-below(lg) {
            font-size: 16px;
          }
          @include respond-below(sm) {
            font-size: 14px;
          }
        }
      }
      th {
        padding: 0 30px 20px;
        border-bottom: 0.5px solid var(--auth-main-color) !important;
        margin-bottom: 10px;
      }
      td {
        padding: 10px 30px;
        border-bottom: 0;
      }
      tr:first-child td{
        padding-top: 20px;
      }
      .btn-round {
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }
}
/* End Pending Tutors
************************/