$green: #5FAE32;
$blue: #0180D8;
$orange: #D87B01;
$brown: #6A3C1A;
$red: #CE0E2D;
$green-hover: #518A31;
$blue-hover: #096AAE;
$orange-hover: #C97304;
$dropdown-arrow-green: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15244' data-name='Path 15244' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
$dropdown-arrow-blue: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15244' data-name='Path 15244' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%230180D8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
$dropdown-arrow-orange: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15244' data-name='Path 15244' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%23D87B01' fill-rule='evenodd'/%3E%3C/svg%3E%0A");


:root {
  --base-font: 14px;
  --green: #{$green};
  --oragne: #{$orange};
  --brown: #{$brown};

  --main-color: #{$green};
  --main-color-hover: #{$green-hover};
  --auth-main-color: #{$green};
  --auth-main-color-hover: #{$green-hover};


  --dropdown-arrow: #{$dropdown-arrow-green}
}