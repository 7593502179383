/* Start Add & Edit Student Model
************************/
.add-edit--student-model {
  .modal-dialog{
    max-width: 500px;
  }
  .modal-content {
    border: 1px solid #C6C6C5;
    border-radius: 15px;
  }
  .modal-body {
    padding: 30px 10px 40px;
    text-align: center;
  }
  &--title {
    font-size: 30px;
    font-weight: 400;
    color: var(--auth-main-color);
    line-height: 1;
    margin-bottom: 40px;
  }
  &--form-title {
    font-size: 18px;
    color: #9F9F9F;
    margin-bottom: 20px;
    &.large-margin {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  &--form-group {
    max-width: 300px;
    margin: 0 auto 20px;
  }
  .add-student-btn {
    margin: 40px auto 0;
    max-width: 135px;
    width: 100%;
  }
}
/* End Add & Edit Student Model
************************/