/* Start Student SubAccount
************************/
.student-subaccount-box {
  padding: 30px 40px;
  @include respond-below(xxl) {
    padding: 30px;
  }
  &--title-container {
    margin-bottom: 20px;
    text-align: center;
  }
  &--title {
    font-weight: 400;
    color: var(--auth-main-color);
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 1;
    @include respond-below(sm) {
      font-size: 23px;
    }
  }
  &--subtitle {
    color: #9F9F9F;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 1;
    @include respond-below(sm) {
      font-size: 17px;
    }
  }
  &--add-student {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
    @include respond-below(sm) {
      justify-content: flex-start;
    }
  }
  &--child-box {
    padding: 20px 15px;
    border: 1px solid #C6C6C5;
    border-radius: 3px;
    margin-bottom: 30px;
    @include respond-below(xxl) {
      padding: 20px 10px;
    }
    &-photo {
      width: 120px;
      border: 2px solid var(--oragne);
      border-radius: 50%;
      margin: 0 auto 20px;
      img{
        border-radius: 50%;
      }
    }
    &-name {
      color: #9F9F9F;
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1;
      text-align: center;
    }
    &-passcode {
      font-size: 18px;
      color: #9F9F9F;
      line-height: 1;
      margin-bottom: 20px;
      text-align: center;
      span {
        color: var(--oragne);
      }
    }
    &-btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-below(xxxl) {
        flex-wrap: wrap;
      }
    }
    &-btn {
      font-size: 12px;
      height: fit-content;
      line-height: 1;
      &:not(:last-child) {
        margin: 0 5px;
      }
      @include respond-below(xxxl) {
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
}
/* End Student SubAccount
************************/