/* Start Buttons Style
************************/
.btn-round {
  display: block;
  border: 0;
  outline: 0;
  background: var(--main-color);
  padding: 8px 20px;
  color: #fff !important;
  text-align: center;
  border-radius: 20px;
  font-weight: 700;
  text-transform: capitalize;
  transition: all ease-in-out 0.25s;
  text-decoration: none;
  &:hover {
    background: var(--main-color-hover);
    color: #fff !important;
  }
  &.auth-btn {
    background: var(--auth-main-color);
    &:hover {
      background: var(--auth-main-color-hover);
    }
  }
  &.btn-large {
    padding: 19px 35px;
    border-radius: 36px;
    font-size: 20px;
  }
  &.with-icon {
    text-align: center;
    position: relative;
    display: inline-block;
    svg{
      position: absolute;
      @include ltr() {
        right: 25px;
      }
      @include rtl() {
        left: 25px;
        transform: translateY(-50%) rotate(180deg);
      }
      top: 50%;
      transform: translateY(-50%);
    }
    &.icon-left {
      svg {

        @include ltr() {
          left: 25px;
          right: auto;
        }
        @include rtl() {
          right: 25px;
          left: auto;
        }

      }
    }
  }
}
/* End Buttons Style
************************/