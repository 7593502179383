/* Start Home
************************/
.home {
  min-height: calc(100vh - 111px);
  @include respond-below(xxxl) {
    min-height: calc(100vh - 95px);
  }
  @include respond-below(lg) {
    min-height: calc(100vh - 90px);
  }
  padding: 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
  &--logo{
    width: 230px;
    margin: 0 auto 25px;
    svg {
      width: 100%;
      height: auto;
    }
    @include respond-below(xxxl) {
      width: 180px;
    }
  }
  &--title {
    font-size: 40px;
    line-height: 1;
    font-weight: 400;
    color: var(--brown);
    margin-bottom: 27px;
    @include respond-below(xxxl) {
      font-size: 35px;
    }
    @include respond-below(md) {
      font-size: 25px;
    }
  }
  &--description {
    font-size: 30px;
    line-height: 1.5em;
    max-width: 1048px;
    margin: 0 auto 27px;
    @include respond-below(xxxl) {
      font-size: 25px;
    }
    @include respond-below(md) {
      font-size: 18px;
    }
  }
  .btn-large {
    display: inline-block;
    width: auto;
    @include respond-below(md) {
      font-size: 14px;
    }
  }
}
/* End Home
************************/