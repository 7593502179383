@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin ltr() {
  [dir="ltr"] & {
    @content;
  }
}


@mixin rotateAr() {
  @if($start-direction == right) {
    transform: rotate(180deg);
  }
}

// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;

  @if $position == 'v' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  @else if $position == 'h' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
  }
  @else if $position == 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}


@mixin transition($for: all , $time: 0.25s, $func: ease-in-out, $delay: 0s){
  -webkit-transition: $for $time $func $delay;
  -moz-transition: $for $time $func $delay;
  -o-transition: $for $time $func $delay;
  transition: $for $time $func $delay;
}

@mixin circle($dimensions){
  display: inline-block;
  text-align: center;
  width: $dimensions;
  height: $dimensions;
  line-height: $dimensions;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}




// responsive mixin
$breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 576px,
  // Medium screen / tablet
        md: 768px,
  // Large screen / desktop
        lg: 992px,
  // Extra large screen / wide desktop
        xl: 1200px,

        xxl: 1400px,
        xxxl: 1680px,
);
// Respond above.
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
// respond below
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
// respond between
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
// responsive mixin


@mixin sectionOffset() {
  @include respond-above(xxxl) {
    padding-#{$end-direction}: 200px
  }
}

// rtl & ltr mixib
@mixin ltr() {
  body.site-ltr & {
    @content;
  }
}
@mixin rtl() {
  body.site-rtl & {
    @content;
  }
}