/* Start Footer
************************/
.footer {
  background: var(--auth-main-color);
  padding-top: 60px;
  padding-bottom: 60px;
  &--top {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @include respond-below(lg) {
      display: block;
    }
    @include respond-below(sm) {
      text-align: center;
    }
  }

  &--about {
    color: #FFF;
    flex: 0 0 calc(100% - 750px);
    width: calc(100% - 750px);
    @include ltr(){
      padding-right: 90px;
    }
    @include rtl(){
      padding-left: 90px;
    }
    @include respond-below(xxxl){
      flex: 0 0 calc(100% - 700px);
      width: calc(100% - 700px);
      @include ltr(){
        padding-right: 50px;
      }
      @include rtl(){
        padding-left: 50px;
      }
    }
    @include respond-below(xxl) {
      flex: 0 0 calc(100% - 650px);
      width: calc(100% - 650px);
      @include ltr(){
        padding-right: 50px;
      }
      @include rtl(){
        padding-left: 50px;
      }
    }
    @include respond-below(xl) {
      flex: 0 0 100%;
      width: 100%;
      @include ltr(){
        padding-right: 0;
      }
      @include rtl(){
        padding-left: 0;
      }
    }
    &--title {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 23px;
    }
    &--description {
      font-size: 18px;
      margin: 0;
      line-height: 29px;
    }
  }

  &--get-app {
    @include ltr() {
      padding-left: 70px;
      padding-right: 60px;
    }
    @include rtl() {
      padding-right: 70px;
      padding-left: 60px;
    }
    @include respond-below(xxl) {
      @include ltr(){
        padding-left: 50px;
        padding-right: 40px;
      }
      @include rtl(){
        padding-right: 50px;
        padding-left: 40px;
      }
    }
    @include respond-below(xl) {
      @include ltr(){
        padding-left: 150px;
        padding-right: 200px;
      }
      @include rtl(){
        padding-right: 150px;
        padding-left: 200px;
      }
    }
    @include respond-below(lg) {
      @include ltr(){
        padding-left: 0;
        padding-right: 0;
      }
      @include rtl(){
        padding-right: 0;
        padding-left: 0;
      }
    }
    @include respond-below(sm) {
      flex: 0 0 100%;
      width: 100%;
      text-align: center;
      padding-top: 60px;
    }

    &-title {
      color: #fff;
      font-size: 18px;
      margin-bottom: 20px;
    }
    &-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      li:not(:last-child) {
        margin-bottom: 20px;
      }
      a {
        display: inline-block;
      }
    }
  }

  &--links {
    &-group {
      display: flex;
      @include ltr(){
        padding-left: 80px;
      }
      @include rtl(){
        padding-right: 80px;
      }
      flex-wrap: wrap;
      margin-top: 60px;
      @include ltr(){
        border-left: 1px solid #fff;
      }
      @include rtl(){
        border-right: 1px solid #fff;
      }
      @include respond-below(xxxl) {
        @include ltr(){
          padding-left: 60px;
        }
        @include rtl(){
          padding-right: 60px;
        }
      }
      @include respond-below(xxl) {
        @include ltr(){
          padding-left: 40px;
        }
        @include rtl(){
          padding-right: 40px;
        }
      }
      @include respond-below(xl) {
        @include ltr(){
          border-left: none;
          padding-left: 0;
        }
        @include rtl(){
          border-right: none;
          padding-right: 0;
        }
      }
      @include respond-below(lg) {
        justify-content: space-between;
      }
      @include respond-below(sm) {
        justify-content: center;
      }

    }
    &-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      &:not(:first-child) {
        @include ltr(){
          margin-left: 50px;
        }
        @include rtl(){
          margin-right: 50px;
        }
        @include respond-below(xl) {
          @include ltr(){
            margin-left: 100px;
          }
          @include rtl(){
            margin-right: 100px;
          }
        }
        @include respond-below(lg) {
          @include ltr(){
            margin-left: 0;
          }
          @include rtl(){
            margin-right: 0;
          }
        }
        @include respond-below(lg) {
          @include ltr(){
            margin-left: 80px;
          }
          @include rtl(){
            margin-right: 80px;
          }
        }
      }
      li:not(:last-child) {
        margin-bottom: 20px;
      }
      a {
        display: inline-block;
        font-size: 18px;
        color: #FFF;
        text-decoration: none;
        transition: all 0.25s ease-in-out;
        &:hover {
          text-decoration: none;
          color: $blue-hover;
        }
      }
    }
  }

  &--social {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    @include respond-below(lg) {
      padding-top: 50px;
    }
    &-title {
      font-size: 32px;
      font-weight: 400;
      color: #FFF;
      white-space: nowrap;
      @include respond-below(sm){
        font-size: 27px;
      }
    }
    &-links {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        padding: 0 10px;
        &:first-child {
          @include ltr(){
            padding-left: 0;
          }
          @include rtl(){
            padding-right: 0;
          }
        }
        &:last-child {
          @include ltr(){
            padding-right: 0;
          }
          @include rtl(){
            padding-left: 0;
          }
        }
      }
      a {
        display: inline-block;
        transition: all 0.25s ease-in-out;
        opacity: 1;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &--bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-top: 20px;
    border-top: 2px solid #FFF;
    font-size: 16px;
    .copyright {
      color: #FFF;
      margin-bottom: 0;
      @include ltr(){
        margin-right: 6px;
      }
      @include rtl(){
        margin-left: 6px;
      }
    }
    a {
      display: inline-block;
      @include ltr(){
        margin-left: 24px;
      }
      @include rtl(){
        margin-right: 24px;
      }
      color: #FFF;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      opacity: 1;
      &:hover {
        text-decoration: none;
        color: #FFF;
        opacity: 0.8;
      }
    }
  }
}

/* End Footer
************************/