/* Start Header
************************/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  @include ltr() {
    right: 0;
  }
  @include rtl() {
    left: 0;
  }
  z-index: 999;
  padding: 20px 0;
  transition: all 0.35s ease-in-out;
  background: #FFF;
  box-shadow: 0 4px 6px rgba(#000, 0.1);

  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &--nav {
    display: flex;
    position: relative;
    bottom: 0;
    margin-bottom: 0;
    transition: all 0.35s ease-in-out;
    justify-content: center;
    @include respond-below(lg) {
      margin-top: 0;
      padding: 35px 20px;
      position: fixed;
      top: 0;
      @include ltr() {
        left: -321px;
      }
      @include rtl() {
        right: -321px;
      }
      width: 320px;
      height: 100%;
      box-shadow: 0 0 25px rgba(#000, 0.16);
      overflow: auto;
      background: #FFF;
      display: block;
      z-index: 9999999;
      & > a {
        @include ltr() {
          padding-right: 27px;
        }
        @include rtl() {
          padding-left: 27px;
        }
        position: relative;
        display: inline-block;
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.062' height='8.04' viewBox='0 0 14.062 8.04'%3E%3Cpath id='Icon_ionic-ios-arrow-down' data-name='Icon ionic-ios-arrow-down' d='M13.221,16.863l5.317-5.321a1,1,0,0,1,1.419,0,1.013,1.013,0,0,1,0,1.423l-6.025,6.029a1,1,0,0,1-1.386.029L6.481,12.969A1.005,1.005,0,0,1,7.9,11.546Z' transform='translate(-6.188 -11.246)' fill='%23fff'/%3E%3C/svg%3E%0A");
          display: inline-block;
          opacity: 0.7;
          transition: all 0.25s ease-in-out;
          position: absolute;
          top: 0;
          @include ltr() {
            right: 0;
          }
          @include rtl() {
            left: 0;
          }
        }
      }
    }
    @include respond-below(sm) {
      width: 270px;
    }

    &-main {
      margin-bottom: 0;
      display: flex;
      padding: 0;
      list-style: none;
      @include respond-below(lg) {
        display: block;
      }
      @include respond-below(xxl) {
        @include ltr() {
          margin-left: auto;
        }
        @include rtl() {
          margin-right: auto;
        }
      }

      & > li {
        position: relative;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          @include ltr() {
            margin-right: 25px;
          }
          @include rtl() {
            margin-left: 25px;
          }
        }
        @include respond-below(lg) {
          &:not(:last-child) {
            @include ltr() {
              margin-right: 0;
            }
            @include rtl() {
              margin-left: 0;
            }
          }
          padding: 20px 0;
          border-bottom: 1px solid rgba(#000, 0.1);
        }
        & > a:not(.nav-btn-link) {
          color: #9F9F9F;
          transition: all 0.25s ease-in-out;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          display: inline-flex;
          align-items: center;
          height: 100%;
          @include respond-below(lg) {
            display: flex;
            padding: 0;
            justify-content: space-between;
            width: 100%;
          }
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            bottom: -22px;
            @include ltr() {
              left: 0;
            }
            @include rtl() {
              right: 0;
            }
            border: 3px solid var(--auth-main-color);
            opacity: 0;
            transition: all 0.25s ease-in-out;
            background: var(--auth-main-color);
            z-index: 2;
            @include respond-below(lg) {
              content: none;
            }
          }
          &:hover {
            color: var(--auth-main-color);
          }
          &.active {
            color: var(--auth-main-color);
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
    &-dropdown {
    }
    &-dropdown-link {
      color: #9F9F9F;
      transition: all 0.25s ease-in-out;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      height: 100%;
      @include ltr() {
        margin-right: 40px;
      }
      @include rtl() {
        margin-left: 40px;
      }
      position: relative;
      @include respond-below(lg) {
        white-space: nowrap;
      }
      svg {
        @include ltr() {
          margin-left: 10px;
        }
        @include rtl() {
          margin-right: 10px;
        }
        path {
          transition: all ease-in-out 0.25s;
        }
      }
      &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15231' data-name='Path 15231' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%23979797' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
        @include ltr() {
          padding-left: 10px;
        }
        @include rtl() {
          padding-right: 10px;
        }
      }

      &.show,
      &:hover{
        color: var(--auth-main-color);
        svg path {
          fill: var(--auth-main-color);
        }
      }
      &.show:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15231' data-name='Path 15231' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");;
      }
    }
    &-dropdown-menu {
      background: #fff;
      border: 1px solid #E2E2E2;
      padding: 5px 0;
      border-radius: 3px;
      box-shadow: 0 4px 6px rgba(#000, 0.1);
      max-width: 195px;
      width: 100%;
      top: 18px !important;
      @include respond-below(md) {
        position: static !important;
        border: 0;
        box-shadow: none;
        max-width: 100%;
        transform: none !important;
      }
      &-item {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        font-size: 16px;
        color: #9F9F9F;
        transition: all ease-in-out 0.25s;
        &:hover {
          color: var(--auth-main-color)
        }
      }
    }
  }

  .brand-wrapper {
    .brand-box {
      display: inline-flex;
      svg {
        height: 100%;
        width: auto;
      }
      @include respond-below(xxxl) {
        height: 50px;
      }
    }
    @include respond-below(lg) {
      position: static;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .brand-box {
        @include ltr() {
          margin-right: 30px;
        }
        @include rtl() {
          margin-left: 30px;
        }
        height: 50px;
      }
    }
  }


  .header-mobile-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(#000, 0.7);
    z-index: 9999998;
    backdrop-filter: blur(10px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.25s ease-in-out;
    @include respond-above(lg) {
      display: none;
    }
    .close-btn {
      position: absolute;
      top: 30px;
      @include ltr() {
        right: 30px;
      }
      @include rtl() {
        left: 30px;
      }
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background: var(--auth-main-color);
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      &:hover {
        background: #FFF;
        path {
          fill: var(--auth-main-color);
        }
      }
      @include respond-below(sm) {
        display: none;
      }
    }
  }
  .header-toggle {
    display: inline-block;
    background: var(--auth-main-color);
    border-radius: 20px;
    padding: 10px 20px;
    line-height: 0;
    @include respond-above(lg) {
      display: none;
    }
  }

  &.active {
    .header-toggle {
      svg {
        transform: rotate(180deg);
      }
    }
    .header-mobile-overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    .header--nav {
      @include ltr() {
        left: 0;
      }
      @include rtl() {
        right: 0;
      }
    }
  }



}
/* End Header
************************/