/* Start Inputs Style
************************/
.form {
  &--group {
    margin-bottom: 20px;
    position: relative;
    &-fess-group {
      position: relative;
      .fees {
        border: 1px solid var(--auth-main-color);
        color: var(--auth-main-color);
        display: inline-block;
        font-weight: 700;
        padding: 6.5px 20px;
        border-radius: 18px;
        position: absolute;
        top: 0;
        @include ltr(){
          left: 0;
        }
        @include rtl() {
          right: 0;
        }
      }
    }

    .select2-selection {
      border: 1px solid #9F9F9F;
      border-radius: 20px;
      height: auto;
    }
    .select2-selection__placeholder {
      color: rgba(#9F9F9F, 0.5) !important;
      font-weight: 700;
    }
    .select2-selection__rendered {
      padding: 4px;
      text-align: center;
      color: #707070 !important;
      text-transform: capitalize;
    }
   .select2-selection__arrow{
     top: 50% !important;
     transform: translateY(-50%);
     @include ltr(){
       right: 20px !important;
     }
     @include rtl() {
       left: 20px !important;
     }
     height: auto !important;
     width: auto !important;
     &>b {
       display: none;
     }
     &:after {
       content: var(--dropdown-arrow);
     }
   }
  }

  &--control {
    width: 100%;
    outline: none;
    padding: 7px;
    border-radius: 20px;
    text-align: center;
    border: 1px solid #9F9F9F;
    resize: none;
    &::placeholder {
      color: rgba(#9F9F9F, 0.5);
      text-transform: capitalize;
      font-weight: 700;
    }
  }
  &--error {
    &-text {
      color: $red;
      font-size: 13px;
      font-weight: 500;
      padding-top: 0;
      display: block;
      line-height: 1;
      height: 0;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }
    .form--control {
      border-color: $red;
    }
    .form--error-text {
      height: 27px;
      padding-top: 10px;
      opacity: 1;
    }
  }
  &--custom-radio {
    cursor: pointer;
    display: inline-block;
    input {
      display: none;
    }
    .circle {
      display: inline-block;
      vertical-align: middle;
      flex: 0 0 15px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 2px solid #9F9F9F;
    }
    .value {
      display: inline-block;
      vertical-align: middle;
      flex: 0 0 calc(100% - 15px);
      color: #9F9F9F;
      @include ltr(){
        margin-left: 10px;
      }
      @include rtl() {
        margin-right: 10px;
      }
    }
    &-container {
      display: flex;
      align-items: center;
    }
    input:checked + &-container {
      span {
        color: var(--auth-main-color);
      }
      .circle {
        background: var(--auth-main-color);
      }
    }

  }

  &--custom-upload-profile-photo{
    cursor: pointer;
    input {
      display: none;
    }
    .uploaded-photo {
      width: 120px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      display: block;
      img{
        width: 100%;
        border-radius: 50%;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.5);
        position: absolute;
        top: 0;
        @include ltr(){
          left: 0;
        }
        @include rtl() {
          right: 0;
        }
      }
    }
    .value {
      display: inline-block;
      color: var(--auth-main-color);
      font-weight: 700;
      padding: 7px 23px;
      background: #fff;
      border: 1px solid var(--auth-main-color);
      border-radius: 20px;
      z-index: 1;
      transition: all ease-in-out 0.25s;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      white-space: nowrap;
      &:hover {
        background: var(--auth-main-color);
        color: #fff;
      }
    }
  }

  &--custom-upload-file {
    cursor: pointer;
    margin: 0;
    position: relative;
    display: block;
    input {
      display: none;
    }
    &--container {
      padding: 7px 0;
      display: block;
      @include ltr(){
        text-align: right;
        padding-right: 25px;
      }
      @include rtl() {
        text-align: left;
        padding-left: 25px;
      }
      border: 1px solid #9F9F9F;
      border-radius: 18px;
      color: rgba(#9F9F9F, 0.5);
      font-weight: bold;
      .value {
        display: inline-block;
        padding: 7px 15px;
        color: var(--auth-main-color);
        border: 1px solid var(--auth-main-color);
        border-radius: 18px;
        position: absolute;
        top: 0;
        @include ltr(){
          left: 0;
        }
        @include rtl() {
          right: 0;
        }
      }
    }
  }
  .input-group{
    position: relative;

  }
  &-select-gender-container {
    max-width: 300px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:after {
      content: 'or';
      font-size: 18px;
      color: #9F9F9F;
      position: absolute;
      @include ltr(){
        left: 50%;
      }
      @include rtl() {
        right: 50%;
      }
      transform: translateX(-50%);
      bottom: 0;
    }
  }
  &--custom-gender-radio {
    cursor: pointer;
    input {
      display: none;
    }
    &-photo {
      width: 120px;
      border: 2px solid #C6C6C5;
      border-radius: 50%;
      margin-bottom: 15px;
      display: block;
      img {
        border-radius: 50%;
      }
    }
    &-gender-type {
      display: block;
      font-size: 18px;
      text-align: center;
      color: #9F9F9F;
    }
    input:checked + &-container {
      &>span {
        border-color: var(--oragne);
      }
      &>span {
        color: var(--oragne);
      }
    }
  }
}


.form-check-input {
  width: 15px;
  height: 15px;
  border-color: #9F9F9F;
  margin: 0 !important;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  &:checked {
    background-color: var(--auth-main-color);
    box-shadow: none;
    border-color: var(--auth-main-color);
  }
}
.form-check-label {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  @include ltr(){
    padding-left: 10px;
  }
  @include rtl() {
    padding-right: 10px;
  }
  cursor: pointer;
}
.form-check-input:checked + .form-check-label {
  color: var(--auth-main-color);
}

.input-group-addon {
  position: absolute;
  top: 50%;
  @include ltr(){
    right: 20px;
  }
  @include rtl() {
    left: 20px;
  }
  transform: translateY(-50%);
  svg path {
    fill: var(--auth-main-color);
  }
}
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu){
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.datepicker.dropdown-menu {
  width: 250px;
  .table-condensed {
    width: 100%;
    .day.active,
    .month.active,
    .year.active
    {
      background: var(--auth-main-color);
    }
  }
  .datepicker-days {
    padding: 0 10px;
  }
}

.select2-dropdown {
  .select2-results__option {
    text-align: center;
    transition: all 0.25s ease-in-out;
  }
  .select2-results__option--highlighted{
    background: #eee !important;
    color: #707070 !important;
  }
  .select2-results__option[aria-selected=true]
  {
    background: var(--auth-main-color) !important;
    color: #fff !important;
  }
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

/* End Inputs Style
************************/