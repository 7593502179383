/* Start Courses
************************/
.courses {
  padding: 30px 0 40px;
  &--header{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &--sort-options {
    display: inline-flex;
    align-items: center;
    .select2 {
      @include ltr(){
        margin-right: 20px;
      }
      @include rtl() {
        margin-left: 20px;
      }
      width: 93px !important;
      .select2-selection--single{
        height: auto;
        max-width: 93px;
        border-radius: 18px;
      }
      .select2-selection__rendered {
        padding: 2px 12px;
        height: auto;
        width: 100%;
      }
      .select2-selection__placeholder {
        font-size: 16px;
        color: #9F9F9F;
        line-height: 1;
      }
      .select2-selection__arrow {
        width: auto;
        height: auto;
        top: 50%;
        @include ltr() {
          right: 10px;
        }
        @include rtl() {
          right: 10px;
        }
        transform: translateY(-50%);
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15225' data-name='Path 15225' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%23979797' fill-rule='evenodd'/%3E%3C/svg%3E%0A");;
        }
        &>b{
          display: none;
        }
      }
    }
  }
  &--view-option {
    cursor: pointer;
    svg path {
      fill: #9F9F9F;
      transition: all ease-in-out 0.25s;
    }
    &:not(:last-of-type) {
      @include ltr() {
        margin-right: 20px;
      }
      @include rtl () {
        margin-left: 20px;
      }
    }
    &.active {
      svg path {
        fill: var(--green);
      }
    }
    @include respond-below(sm) {
      display: none;
    }
  }

  &--container {
    display: flex;
    align-items: flex-start;
  }
  &--search-box {
    flex: 0 0 260px;
    width: 260px;
    background: #fff;
    border: 1px solid #C6C6C5;
    border-radius: 3px;
    padding: 20px 40px;
    height: fit-content;
    @include respond-below(xxxl) {
      flex: 0 0 240px;
      width: 240px;
    }
    @include respond-below(xxl) {
      flex: 0 0 220px;
      width: 220px;
    }
    &--title {
      font-size: 18px;
      margin-bottom: 18px;
      color: var(--brown);
      font-weight: 400;
      text-align: center;
      line-height: 1;
      svg path {
        fill: #fff;
      }
      .close-search {
        display: none;
      }
    }
    .btn-round {
      width: 100%;
    }
    @include respond-below(md) {
      position: fixed;
      top: 0;
      @include ltr(){
        left: 0;
      }
      @include rtl() {
        right: 0;
      }
      width: 100%;
      height: 100%;
      z-index: 999;
      padding: 0;
      border: 0;
      transition: all ease-in-out 0.25s;
      overflow: auto;
      &.hide {
        top: 100%;
      }
      &--title {
        background: var(--main-color);
        padding: 20px;
        color: #fff;
        margin: 0;
        position: relative;
        .close-search {
          display: block;
          position: absolute;
          top: 50%;
          @include ltr() {
            left: 20px;
          }
          @include rtl() {
            right: 20px;
          }
          transform: translateY(-50%);
          cursor: pointer;
          padding: 5px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      &--content {
        border-radius: 5px;
        padding: 20px 40px;
      }
    }
  }
  &--filters-container {
    margin-bottom: 18px;
    &:not(:last-of-type) {
      border-bottom: 1px solid #9F9F9F;
    }
    &--title {
      font-size: 16px;
      color: #9F9F9F;
      margin-bottom: 20px;
      line-height: 1;
    }
    &--list {
      list-style: none;
      padding: 0;
      margin: 0;
      .form--group {
        margin-bottom: 15px;
      }
    }
  }
  &--show-more {
    display: inline-block;
    color: #9F9F9F;
    text-decoration: none;
    transition: all ease-in-out 0.25s;
    margin-bottom: 10px;
    svg {
      @include ltr(){
        margin-right: 10px;
      }
      @include rtl() {
        margin-left: 10px;
      }
      path {
        transition: all ease-in-out 0.25s;
      }

    }
    &:hover {
      color: var(--main-color);
      svg path {
        fill: var(--main-color);
      }
    }
  }
  &--list{
    margin: 0 -10px;
    display: flex;
    flex-wrap: wrap;
    &-wrapper {
      flex: 0 0 calc(100% - 260px);
      @include ltr() {
        padding-left: 20px;
        @include respond-below(sm) {
          padding-left: 0;
        }
      }
      @include rtl() {
        padding-right: 20px;
        @include respond-below(sm) {
          padding-right: 0;
        }
      }
      @include respond-below(xxxl) {
        flex: 0 0 calc(100% - 240px);
      }
      @include respond-below(xxl) {
        flex: 0 0 calc(100% - 220px);
      }
      @include respond-below(md) {
        flex: 0 0 100%;
      }
    }
    .course--box {
      height: 100%;
      &-wrapper {
        margin-bottom: 20px;
      }
    }
    &.horizontal-view {
      .course--box-wrapper {
        width: 50%;
        padding: 0 10px;
        @include respond-below(xl) {
          width: 100%;
        }
      }
    }
    &.vertical-view {
      .course--box-wrapper {
        width: 25%;
        padding: 0 10px;
        @include respond-below(xxl) {
          width: calc(100% / 3);
        }
        @include respond-below(lg) {
          width: 50%;
        }
      }
      .course--box {
        flex-direction: column;
        position: relative;
        padding: 20px;
        align-items: stretch;
        &--teacher-photo {
          margin: 0 auto 10px;
        }
        &--teacher-info {
          margin-bottom: 20px;
          max-width: unset !important;
        }
        &--contact-options {
          display: block;
          position: absolute;
          top: 20px;
          @include ltr() {
            right: 20px;
          }
          @include rtl() {
            left: 20px;
          }
          padding: 0;
          margin-bottom: 0;
          @include respond-below(xl) {
            top: 10px;
            @include ltr() {
              right: 10px;
            }
            @include rtl() {
              left: 10px;
            }
          }
          li:not(:last-child) {
            margin-bottom: 10px;
            @include ltr() {
              margin-right: 0;
            }
            @include rtl() {
              margin-left: 0;
            }
          }
        }

      }
    }
  }


  &--filter-btn {
    position: fixed;
    bottom: 20px;
    @include ltr() {
      right: 20px;
    }
    @include rtl() {
      left: 0;
    }
    background: var(--main-color);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 0;
    outline: 0;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0  0 15px 3px rgba(#000, 0.1);
    @include respond-below(md) {
      display: inline-flex;
    }
    @include respond-below(sm) {
      width: 50px;
      height: 50px;
      bottom: 10px;
      @include ltr() {
        right: 10px;
      }
      @include rtl() {
        left: 10px;
      }
      svg {
        width: 25px;
      }
    }
  }
}
/* End Courses
************************/