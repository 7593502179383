/* Start Tutor Profile Model
************************/
.tutor--profile-model {
  &--btn {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $green;
    z-index: 9;
  }
  .modal-dialog {
    max-width: unset;
    // padding: 90px 0;
    padding: 90px 15px 0;
    &--close-btn {
      position: absolute;
      top: 10px;
      width: 25px;
      height: 25px;
      opacity: 1;
      border-radius: 50%;
      background-color: $green;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E");
      z-index: 9;
      @include ltr {
        right: 10px;
      }
      @include rtl {
        left: 10px;
      }
      &:hover {
        background-color: $green-hover;
      }
    }
  }
  .modal-content {
    border-radius: 3px;
    border: 1px solid #C6C6C5;
  }
  .modal-body {
    padding: 45px 30px;
    position: relative;
    // @include respond-below(xxl) {
    //   padding: 30px;
    // }
    @include respond-below(sm) {
      padding: 30px 20px;
    }
  }
  &--content {
    display: none;
    &.active {
      display: block;
    }

  }
  &--photo {
    width: 200px;
    border-radius: 100%;
    background: #fff;
    padding: 10px;
    position: absolute;
    top: -100px;
    height: 200px;
    @include ltr(){
      left: 0;
    }
    @include rtl() {
      right: 0;
    }
    @include respond-below(lg) {
      width: 120px;
      height: 120px;
      top: -50px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &--container {
    display: flex;
    @include respond-below(xl) {
      display: block;
    }
  }
  &--info {
    width: 650px;
    @include ltr(){
      border-right: 1px solid #9F9F9F;
      padding-right: 15px;
    }
    @include rtl() {
      border-left: 1px solid #9F9F9F;
      padding-left: 15px;
    }
    @include respond-below(xxl) {
      width: 100%;
    }
    @include respond-below(xl) {
      @include ltr(){
        border-right: 0;
        padding-right: 0;
      }
      @include rtl() {
        border-left: 0;
        padding-left: 0;
      }
      padding-bottom: 40px;
      border-bottom: 1px solid #9F9F9F;
      margin-bottom: 50px;
    }
  }
  &--personal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    @include ltr(){
      padding-left: 180px;
    }
    @include rtl() {
      padding-right: 180px;
    }
    max-width: 790px;
    @include respond-below(lg) {
      @include ltr(){
        padding-left: 110px;
      }
      @include rtl() {
        padding-right: 110px;
      }
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include respond-below(sm) {
      @include ltr(){
        padding-left: 0;
      }
      @include rtl() {
        padding-right: 0;
      }
      padding-top: 50px;
    }
    .tutor-name {
      font-size: 18px;
      color: #9F9F9F;
      line-height: 1;
      margin-bottom: 10px;
      @include respond-below(md) {
        @include ltr(){
          margin-right: 40px;
        }
        @include rtl() {
          margin-left: 40px;
        }
      }
    }
    .tutor-job {
      font-size: 10px;
      margin-bottom: 0;
      color: #9F9F9F;
      a {
        display: inline-block;
        color: var(--brown);
        @include ltr(){
          padding-left: 10px;
        }
        @include rtl() {
          padding-right: 10px;
        }
        position: relative;
        transition: all ease-in-out 0.25s;
        &:hover {
          color: var(--green);
        }
        &:before {
          content: '';
          width: 1px;
          height: 100%;
          @include ltr(){
            border-left: 1px solid #9F9F9F;
            left: 5px;
          }
          @include rtl() {
            border-right: 1px solid #9F9F9F;
            right: 5px;
          }
          position: absolute;
          top: 0;

        }
      }
    }
    .info-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      @include respond-below(lg) {
        padding: 0 25px;
      }
      @include respond-below(md) {
        padding: 0;
        margin-top: 15px;
        @include ltr(){
          margin-right: 70px;
        }
        @include rtl() {
          margin-left: 70px;
        }
      }
      @include respond-below(sm){
        @include ltr(){
          padding-right: 10px;
        }
        @include rtl() {
          padding-left: 10px;
        }
      }
      li {
        font-size: 12px;
        color: var(--brown);
        text-align: center;
        span {
          color: #9F9F9F;
          display: block;
        }
        &:not(:last-child) {
          @include ltr(){
            margin-right: 33px;
          }
          @include rtl() {
            margin-left: 33px;
          }
        }
      }
    }
    .btn-round {
      @include respond-below(md) {
        margin-top: 15px;
      }

    }
  }
  &--description {
    font-size: 18px;
    color: #9F9F9F;
    margin-bottom: 18px;
    @include respond-below(md) {
      font-size: 16px;
    }
    &:after {
      content: '';
      display: block;
      width: 600px;
      height: 1px;
      background: #9F9F9F;
      margin: 20px auto 0;
      @include respond-below(xl) {
        width: 100%;
      }
    }
  }
  &--media-title {
    font-size: 20px;
    color: #9F9F9F;
    text-align: center;
    line-height: 1;
    margin-bottom: 20px;
  }
  &--media-tab-list {
    display: inline-flex;
    margin: 0 auto 30px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid var(--main-color);
    padding: 0;
    @include respond-below(sm) {
      display: block;
    }
    .media--tab-button {
      background: transparent;
      border: 0;
      padding: 8px 45px;
      font-weight: bold;
      color: var(--main-color);
      @include respond-below(sm) {
        padding: 8px 17px;
        display: block;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
      }
      &.active {
        background: var(--main-color);
        color: #fff;
      }
    }
    li:not(:last-child) .media--tab-button {
      @include respond-below(sm) {
        border-bottom: 1px solid var(--main-color);
      }
      @include ltr(){
        border-right: 1px solid var(--main-color);
        @include respond-below(sm) {
          border-right: 0;
        }
      }
      @include rtl() {
        border-left: 1px solid var(--main-color);
        @include respond-below(sm) {
          border-left: 0;
        }
      }
    }
  }
  .tab-content {
    // max-height: 455px;
    // overflow: auto;
    position: relative;
    // @include ltr(){
    //   padding-right: 20px;
    // }
    // @include rtl() {
    //   padding-left: 20px;
    // }
    // @include respond-below(sm) {
    //   @include ltr(){
    //     padding-right: 0;
    //   }
    //   @include rtl() {
    //     padding-left: 0;
    //   }
    // }

    .tab-pane {
      border-bottom: 0;
      margin-bottom: 0;
    }
    &::-webkit-scrollbar {
      width: 10px;
      @include respond-below(sm) {
        width: unset;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: #EEEEEE;
      border-radius: 10px;
      overflow: hidden;
      @include respond-below(sm) {
        background-color: unset;
        border-radius: unset;
      }
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(#9F9F9F, 0.5);
      border-radius: 10px;
      @include respond-below(sm) {
        background-color: unset;
        border-radius: unset;
      }
    }
  }
  &--course-content-box {
    border: 1px solid #C6C6C5;
    border-radius: 3px;
    padding: 20px;
    padding-bottom: 0;
    position: relative;
    @include ltr {
      padding-right: 48px;
    }
    @include rtl {
      padding-left: 48px;
    }
    @include respond-below(sm) {
      padding: 20px 10px;
    }
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
    &-title {
      font-size: 18px;
      margin-bottom: 10px;
    }
    &-description {
      color: #9F9F9F;
      max-width: 680px;
      line-height: 2em;
      margin-bottom: 10px;
      @include respond-below(xxxl) {
        max-width: 580px;
      }
      @include respond-below(md) {
        max-width: 360px;
      }
    }
    &-program {
      margin-bottom: 20px;
    }
    .reserve-btn {
      position: absolute;
      bottom: 20px;
      @include ltr(){
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      @include respond-below(xxl) {
        @include ltr(){
          right: 10px;
        }
        @include rtl() {
          left: 10px;
        }
      }
      @include respond-below(md) {
        position: static;
        margin-bottom: 20px;
      }
    }
    .course-contact-options {
      position: absolute;
      top: 20px;
      @include ltr(){
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      display: block;
      padding: 0;
      margin: 0;
      @include respond-below(md) {
        top: 10px;
        @include ltr(){
          right: 10px;
        }
        @include rtl() {
          left: 10px;
        }
      }
      @include respond-below(sm) {
        display: none;
      }
      li {
        @include ltr(){
          margin-right: 0;
        }
        @include rtl() {
          margin-left: 0;
        }
        margin-bottom: 20px;
        @include respond-below(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &--schedule {
    // flex: 0 0 calc(100% - 650px);
    width: calc(100% - 650px);
    @include ltr(){
      padding-left: 15px;
    }
    @include rtl() {
      padding-right: 15px;
    }
    // @include respond-below(xxxl) {
    //   flex: 0 0 calc(100% - 650px);
    // }
    @include respond-below(xxl) {
      width: 100%;
      @include ltr(){
        padding-left: 0;
      }
      @include rtl() {
        padding-right: 0;
      }
    }
    // @include respond-below(sm) {
    //   @include ltr(){
    //     padding-left: 0;
    //   }
    //   @include rtl() {
    //     padding-right: 0;
    //   }
    // }
    .selected-days {
      &--title {
        text-align: center;
        font-size: 19px;
        line-height: 1;
        margin-bottom: 20px;
        font-weight: 700;
      }
      &--list {
        list-style: none;
        padding: 0;
        margin: 0;
        // max-height: 200px;
        // overflow: auto;
        // @include ltr(){
        //   padding-right: 20px;
        // }
        // @include rtl() {
        //   padding-left: 20px;
        // }
        @include respond-below(sm) {
          @include ltr(){
            padding-right: 0;
          }
          @include rtl() {
            padding-left: 0;
          }
        }
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #eee;
          border: 2px solid #F8F8F8;
          border-radius: 3px;
          padding: 10px 20px;
          @include respond-below(xxl) {
            padding: 10px;
          }
          @include respond-below(sm) {
            display: block;
          }
          .edit-btn {
            display: inline-block;
            color: var(--auth-main-color);
            font-weight: 500;
            transition: all ease-in-out 0.25s;
            &:hover {
              color: var(--auth-main-color-hover);
            }
            @include respond-below(sm) {
              margin-top: 10px;
            }
          }
          p {
            margin: 0;
            font-weight: 500;
            &.day {
              @include ltr(){
                margin-right: 20px;
              }
              @include rtl() {
                margin-left: 20px;
              }
              @include respond-below(xxl){
                @include ltr(){
                  margin-right: 10px;
                }
                @include rtl() {
                  margin-left: 10px;
                }
              }
              @include respond-below(md) {
                @include ltr(){
                  margin-right: 0;
                }
                @include rtl() {
                  margin-left: 0;
                }
                margin-bottom: 10px;
              }
            }
          }
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        &::-webkit-scrollbar {
          width: 10px;
          @include respond-below(sm) {
            width: unset;
          }
        }
        &::-webkit-scrollbar-track {
          background: #EEEEEE;
          border-radius: 20px;
          @include respond-below(sm) {
            background: unset;
            border-radius: unset;
          }
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(#9F9F9F, 0.5);
          border-radius: 20px;
          @include respond-below(sm) {
            background: unset;
            border-radius: unset;
          }
        }
      }
    }
  }
  &--payment-confirmation-container {
    margin-top: 75px;
    display: flex;
    justify-content: center;
    @include respond-below(xxl) {
      display: block;
    }
  }
  &--payment-confirmation {
    flex: 0 0 745px;
    @include respond-below(xxxl){
      flex: 0 0 575px;
    }
    @include respond-below(xxl) {
      margin-bottom: 30px;
    }
    &-title {
      text-align: center;
      font-size: 19px;
      flex: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    &-box {
      background: #eee;
      border: 2px solid #F8F8F8;
      padding: 20px;
      border-radius: 3px;
    }
    &-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @include respond-below(sm) {
        display: block;
      }
      .box-title {
        font-size: 16px;
        margin: 0;
        @include respond-below(sm) {
          margin-bottom: 5px;
        }
      }
      .cost {
        font-size: 16px;
        margin: 0;
        color: var(--auth-main-color);
      }
    }
    &-courses-list {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 245px;
      overflow: auto;
      @include ltr(){
        padding-right: 15px;
        @include respond-below(sm) {
          padding-right: 0;
        }
      }
      @include rtl() {
        padding-left: 15px;
        @include respond-below(sm) {
          padding-left: 0;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        @include respond-below(sm) {
          width: unset;
        }
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        @include respond-below(sm) {
          background-color: unset;
          border-radius: unset;
        }
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(#9F9F9F, 0.5);
        border-radius: 10px;
        @include respond-below(sm) {
          background-color: unset;
          border-radius: unset;
        }
      }
    }
    &-course-item {
      display: flex;
      justify-content: space-between;
      @include respond-below(sm) {
        display: block;
        position: relative;
      }
      p {
        margin: 0;
        line-height: 1;
        font-weight: 500;
        &.enble {
          color: var(--auth-main-color);
        }
      }
      .course-main-info {
        @include ltr(){
          margin-right: 35px;
        }
        @include rtl() {
          margin-left: 35px;
        }
        @include respond-below(md) {
          @include ltr(){
            margin-right: 10px;
          }
          @include rtl() {
            margin-left: 10px;
          }
        }
        @include respond-below(sm) {
          margin-bottom: 20px;
        }
        &--name {
          margin-bottom: 10px;
        }
        &--cost {
          color: var(--auth-main-color);
        }
      }
      .course-detail-info {
        list-style: none;
        padding: 0;
        margin: 0;
        max-width: 430px;
        width: 100%;
        & > li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        .time-day {
          display: flex;
          justify-content: space-between;
          @include respond-below(sm) {
            display: block;
          }
        }
      }
      .remove-icon-box {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #FF7E7E;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease-in-out 0.25s;
        @include respond-below(sm){
          position: absolute;
          top: 0;
          @include ltr(){
            right: 0;
          }
          @include rtl() {
            left: 0;
          }
        }
        svg {
          width: 10px;
          path  {
            fill: #fff;
          }
        }
        &:hover {
          background: #cb3a3a;
        }
      }
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    &--form-group {
      display: flex;
      align-items: center;
      @include respond-below(sm) {
        display: block;
        margin-bottom: 25px;
      }
      &>label {
        flex: 0 0 110px;
        font-size: 16px;
        @include ltr(){
          margin-right: 25px;
        }
        @include rtl() {
          margin-left: 25px;
        }
        @include respond-below(sm) {
          margin-bottom: 10px;
        }
      }
      &> span {
        display: inline-block;
        font-size: 11px;
        color: #9F9F9F;
        @include ltr(){
          padding-left: 20px;
        }
        @include rtl() {
          padding-right: 20px;
        }
      }
      .form--control {
        max-width: 315px;
        @include respond-below(sm) {
          max-width: 100%;
        }
      }
      .form--group {
        margin-bottom: 0;
        .select2-container {
          width: 100% !important;
          margin-bottom: 10px;
        }
        .select2-selection {
          width: 135px;
          @include respond-below(sm) {
            width: 100%;
          }
        }
        .select2-selection__arrow:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15240' data-name='Path 15240' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.434l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.434Z' transform='translate(-581.59 -115.003)' fill='%239f9f9f' fill-rule='evenodd' opacity='0.5'/%3E%3C/svg%3E%0A");;
        }
      }
      &.require {
        &>label:after {
          content: "*";
          display: inline-block;
          font-size: 16px;
          color: #FF7E7E;
          @include ltr(){
            margin-left: 3px;
          }
          @include rtl() {
            margin-right: 3px;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &-cards-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      margin-top: 25px;
      li {
        img {
          height: 50px;
        }
        &:not(:last-child) {
          @include ltr(){
            margin-right: 10px;
          }
          @include rtl() {
            margin-left: 10px;
          }
        }
      }
    }

  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    @include respond-below(sm) {
      justify-content: center;
    }
    .btn-round {
      flex: 0 0 135px;
      @include respond-below(sm) {
        flex: 0 0 100px;
      }
      &:not(:last-child) {
        @include ltr(){
          margin-right: 30px;
        }
        @include rtl() {
          margin-left: 30px;
        }
        @include respond-below(sm) {
          @include ltr(){
            margin-right: 10px;
          }
          @include rtl() {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .confirmation-container {
    .tutor--profile-model--footer {
      margin-top: 50px;
    }
  }


  // .calendar-status
  .calendar-status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    gap: 10px 25px;
    &--item {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      &::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: lightgray;
      }

      &.available::before {
        background:$green;
      }
      &.busy::before {
        background: $orange;
      }
      &.selected::before {
        background: $blue;
      }
      &.reserved::before {
        background: $red;
      }
    }
  }
}
/* End Tutor Profile Model
************************/