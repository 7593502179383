/* Start Authentication Style
************************/
.auth {
  padding: 30px 0 40px;
  &--tabs-list{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    &> li {
      max-width: 136px;
      width: 100%;
    }
    &.login-tab-list {
      @include respond-below(sm) {
        li {
          max-width: calc(100% / 3);
        }
      }
    }
  }
  &--tab-button {
    width: 100%;
    border: 0;
    background: #9F9F9F;
    color: #fff;
    text-transform: capitalize;
    border-radius: 18px 18px 0 0;
    padding: 8px;
    &.active {
      padding-top: 15px;
      background: var(--auth-main-color);
      &.kid-tab {
        --auth-main-color: #{$blue};
      }
      &.tutor-tab {
        --auth-main-color: #{$orange};
      }
    }
  }
  &--box {
    max-width: 408px;
    width: 100%;
    margin: auto;
    background: #fff;
    border: 3px solid var(--auth-main-color);
    padding: 25px 50px 30px;
    @include respond-below(sm) {
      padding: 25px 15px 30px;
    }
    &.large-box {
      max-width: 540px;
      padding: 18px 60px 24px;
      @include respond-below(sm) {
        padding: 18px 15px 24px;
      }
    }
    &--header {
      margin-bottom: 40px;
      &.large-margin {
        margin-bottom: 76px;
      }
      &.small-margin {
        margin-bottom: 20px;
      }
    }
  }
  &--title {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    line-height: 1;
    margin: 0;

  }
  &--description {
    line-height: 20px;
    margin-bottom: 0;
    margin: auto;
    margin-top: 28px;
    text-align: center;
    max-width: 248px;

  }
  &--forget-password-link {
    display: inline-block;
    margin-top: 28px;
    color: var(--auth-main-color);
    text-decoration: underline;
    text-transform: capitalize;
    transition: all ease-in-out 0.25s;
    &:hover {
      color: var(--auth-main-color-hover);
    }

  }
  &--have-account {
    margin-top: 18px;
    text-align: center;
    margin-bottom: 0;
    a {
      color: var(--auth-main-color);
      &:hover {
        color: var(--auth-main-color-hover);
      }
    }
  }
  &--login-with {
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    &> p {
      margin: 0;
      @include ltr(){
        margin-right: 20px;
      }
      @include rtl(){
        margin-left: 20px;
      }
    }
    .social-list {
      margin: 0;
      display: inline-flex;
      padding: 0;
      li:not(:last-child) {
        @include ltr(){
          margin-right: 20px;
        }
        @include rtl() {
          margin-left: 20px;
        }
      }
    }
    .social-item {
      width: 35px;
      height: 35px;
      border-radius: 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 0.25s;
      &.facebook {
        background: #3C5B9B;
        &:hover {
          background: #314a7f;
        }
      }
      &.linkedin {
        background: #0173B2;
        &:hover {
          background: #036599;
        }
      }
      &.googleplus {
        background: #F63D27;
        &:hover {
          background: #d53523;
        }
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .tab-pane {
    .auth--box {
      min-height: 390px;
    }
  }
  .tab-kid-content {
    --auth-main-color: #{$blue};
    --auth-main-color-hover: #{$blue-hover};
  }
  .tab-tutor-content {
    --auth-main-color: #{$orange};
    --auth-main-color-hover: #{$orange-hover};
    --dropdown-arrow: #{$dropdown-arrow-orange};
  }
  .tab-student-content {
    --auth-main-color: #{$green};
    --auth-main-color-hover: #{$green-hover};
  }



  .btn-round {
    margin: 30px auto 0;
    max-width: 300px;
    &.small-margin {
      margin-top: 0;
    }
  }
  .btn-round {
    width: 100%;
  }
}
/* End Authentication Style
************************/