/* Start Course Box
************************/
.course--box {
  background: #fff;
  border: 1px solid #C6C6C5;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 30px;
  @include ltr() {
    padding-left: 70px;
    @include respond-below(xxxl) {
      padding: 19px 20px;
    }

  }
  @include rtl() {
    padding-right: 70px;
    @include respond-below(xxxl) {
      padding-right: 30px;
    }
    @include respond-below(lg) {
      padding-right: 20px;
    }
  }
  @include respond-below(xxxl) {
    padding: 19px 20px;
  }
  &--teacher-photo {
    flex: 0 0 100px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
   @include respond-below(xxl) {
     flex: 0 0 70px;
     width: 70px;
     height: 70px;
   }
    @include respond-below(xl) {
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
    }
    @include respond-below(lg) {
      flex: 0 0 70px;
      width: 70px;
      height: 70px;
    }
  }
  &--teacher-info {
    text-align: center;
    max-width: 255px;
    width: 100%;
    @include respond-below(xxxl) {
      max-width: 210px;
    }
    @include respond-below(xxl) {
      max-width: 160px;
    }
    @include respond-below(xl) {
      max-width: 240px;
    }
    @include respond-below(lg) {
      max-width: 160px;
    }
    .name {
      font-size: 18px;
      color: #9F9F9F;
      line-height: 1;
      margin-bottom: 16px;
      text-decoration: none;
      display: inline-block;
      transition: all ease-in-out 0.25s;
      &:hover {
        color: var(--green);
      }
    }
    .job {
      font-size: 10px;
      margin-bottom: 13px;
      line-height: 1;
      color: #9F9F9F;
      a {
        display: inline-block;
        color: var(--brown);
        @include ltr() {
          padding-left: 10px;
        }
        @include rtl() {
          padding-right: 10px;
        }
        position: relative;
        transition: all ease-in-out 0.25s;
        &:hover {
          color: var(--green);
        }
        &:before {
          content: '';
          width: 1px;
          height: 100%;
          @include ltr() {
            border-left: 1px solid #9F9F9F;;
          }
          @include rtl() {
            border-right: 1px solid #9F9F9F;;
          }
          position: absolute;
          top: 0;
          left: 5px;
        }
      }
    }
    .info-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      li {
        font-size: 12px;
        color: var(--brown);
        span {
          color: #9F9F9F;
        }
      }
    }
  }
  &--actions {

  }
  &--contact-options {
    list-style: none;
    padding: 0 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    li:not(:last-child) {
      @include ltr() {
        margin-right: 20px;
      }
      @include rtl() {
        margin-left: 20px;
      }
    }
  }
  @include respond-below(sm) {
    flex-direction: column;
    position: relative;
    padding: 20px;
    align-items: stretch;
    &--teacher-photo {
      margin: 0 auto 10px;
    }
    &--teacher-info {
      margin: 0 auto 20px;
    }
    &--contact-options {
      display: block;
      position: absolute;
      top: 20px;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      padding: 0;
      margin-bottom: 0;
      li:not(:last-child) {
        margin-bottom: 20px;
        @include ltr() {
          margin-right: 0;
        }
        @include rtl() {
          margin-left: 0;
        }
      }
    }
  }
}
/* End Course Box
************************/