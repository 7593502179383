/* Start Tutor Complete Profile Model
**************************************/
.tutor--complete-profile-model {
  .modal-dialog {
    max-width: 1200px;

  }
  .modal-content {
    border: 1px solid #C6C6C5;
    border-radius: 17px;
  }
  .modal-body {
    padding: 40px 0;
  }
  &--steps-list {
    list-style: none;
    padding: 0;
    margin: 0 auto 40px;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    @include respond-below(sm) {
      padding: 0 25px;
    }
    .step-number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #DEDEDE;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      position: relative;
      z-index: 1;
      &:not(:first-child) {
        &:before {
          content: '';
          display: block;
          width: 60px;
          height: 3px;
          background: #DEDEDE;
          position: absolute;
          top: 50%;
          @include ltr(){
            left: -70px;
          }
          @include rtl() {
            right: -70px;
          }
          transform: translateY(-50%);
          z-index: -1;
          border-radius: 15px;
          @include respond-below(sm) {
            display: none;
          }
        }
      }
      &.current-step {
        background: var(--auth-main-color);
        &:before {
          background: var(--auth-main-color);
        }
      }
      &.complete-step {
        background: var(--green);
        &:before {
          background: var(--green);
        }
      }
    }
  }
  &--steps-container {
    display: none;
    &-title {
      text-align: center;
      color: var(--auth-main-color);
      font-size: 30px;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 30px;
    }
    .steps-controller-container {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .with-icon {
        max-width: 135px;
        width: 100%;
        &.icon-left {
          @include ltr(){
            margin-right: 40px;
            @include respond-below(sm) {
              margin-right: 10px;
            }
          }
          @include rtl() {
            margin-left: 40px;
            @include respond-below(sm) {
              margin-left: 10px;
            }
          }
        }
        &:last-child {
          @include ltr(){
            margin-right: 0;
          }
          @include rtl() {
            margin-left: 0;
          }
        }
      }
    }
    &.active {
      display: block;
    }
  }
  &--first-step {
    padding: 0 100px;
    @include respond-below(xl) {
      padding: 0 40px;
    }
    @include respond-below(md) {
      padding: 0 50px;
    }
    @include respond-below(sm) {
      padding: 0 25px;
    }
    &-basic-info {
      flex: 0 0 430px;
      width: 430px;
      @include respond-below(lg) {
        width: 100%;
      }
    }
    &-basic-info-container {
      display: flex;
      align-items: center;
      @include respond-below(sm) {
        display: block;
      }
      &:not(:last-of-type) {
        margin-bottom: 20px;

      }
      @include respond-below(lg) {
        &:last-child {
          margin-bottom: 30px;
        }
      }
      .label {
        margin-bottom: 0;
        max-width: 90px;
        width: 100%;
        color: #9F9F9F;
        @include ltr(){
          margin-right: 40px;
        }
        @include rtl() {
          margin-left: 40px;
        }
        @include respond-below(sm) {
          margin-bottom: 10px;
        }
        &.small-margin {
          @include ltr(){
            margin-right: 20px;
          }
          @include rtl() {
            margin-left: 20px;
          }
          @include respond-below(lg) {
            @include ltr(){
              margin-right: 40px;
            }
            @include rtl() {
              margin-left: 40px;
            }
          }
        }
      }
      .form--group {
        margin: 0;
        flex: 0 0 300px;
        @include respond-below(lg) {
          flex-grow: 1;
        }
      }
    }

  }
  &--second-step {
    padding: 0 60px;
    @include respond-below(sm) {
      padding: 0 25px;
    }
    .steps-controller-container {
      margin-top: 140px;
      @include respond-below(lg) {
        margin-top: 70px;
      }
    }
    .course-box-content {
      @include ltr(){
        padding-right: 20px;
        border-right: 1px solid #DEDEDE;
      }
      @include rtl() {
        padding-left: 20px;
        border-left: 1px solid #DEDEDE;
      }
      position: relative;
      @include respond-below(lg) {
        margin-bottom: 40px;
      }
      @include respond-below(md) {
        @include ltr(){
          padding-right: 0;
          border-right: 0;
        }
        @include rtl() {
          padding-left: 0;
          border-left: 0;
        }
      }
      .upload-files-list {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        position: absolute;
        @include ltr(){
          left: 0;
        }
        @include rtl() {
          right: 0;
        }
        margin-top: 20px;
        @include ltr(){
          padding-right: 20px;
        }
        @include rtl() {
          padding-left: 20px;
        }
        width: 100%;
        @include respond-below(lg) {
          position: static;
        }
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .video-name {
            color: #9F9F9F;
            margin-bottom: 0;
          }
          .remove-video {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #FF7E7E;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all ease-in-out 0.25s;
            svg {
              width: 10px;
              path  {
                fill: #fff;
              }
            }
            &:hover {
              background: #cb3a3a;
            }
          }
        }
      }
      .add-video-btn {
        width: 100%;
        background: #DEDEDE;
      }
      .label {
        margin: 0;
        color: #DEDEDE;
      }
      .form--control,
      .form--custom-upload-file--container
      {
        border-color: #DEDEDE;
      }
      .fees,
      .form--custom-upload-file--container span
      {
        border-color: #DEDEDE;
        color: #DEDEDE;
      }
      &--title {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px;
      }
      &--fees {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #9F9F9F;

        .label {
          @include ltr(){
            margin-right: 20px;
          }
          @include rtl() {
            margin-left: 20px;
          }
          @include respond-below(sm) {
            @include ltr(){
              margin-right: 10px;
            }
            @include rtl() {
              margin-left: 10px;
            }
          }
        }
        .form--group-fess-group {
          max-width: 165px;
        }
      }
      &--video-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
          @include ltr(){
            margin-right: 25px;
          }
          @include rtl() {
            margin-left: 25px;
          }
        }
        .form--group {
          margin-bottom: 0;
          flex-grow: 1;
        }
      }
      &--upload-video {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include respond-below(xxl) {
          display: block;
          .label {
            margin-bottom: 10px;
          }
        }
        @include respond-below(sm) {
          display: block;
          &> .label {
            margin-bottom: 10px;
            max-width: 100% !important;
          }
        }
        .label {
          max-width: 80px;
          @include ltr(){
            margin-right: 10px;
          }
          @include rtl() {
            margin-left: 10px;
          }
        }
        .form--group {
          margin-bottom: 0;
          flex-grow: 1;
        }
      }
      &.no-border{
        @include ltr(){
          border-right: none;
          padding-right: 0;
        }
        @include rtl() {
          border-left: none;
          padding-left: 0;
        }
      }
      &.selected-course {
        @include ltr(){
          border-right-color: #9F9F9F;
        }
        @include rtl() {
          border-left-color: #9F9F9F;
        }
        .label {
          color: #9F9F9F;
        }
        .form--control,
        .form--custom-upload-file--container
        {
          border-color: #9F9F9F;
        }
        .fees,
        .form--custom-upload-file--container span
        {
          border-color: var(--auth-main-color);
          color: var(--auth-main-color);
        }
        .add-video-btn {
          background: var(--auth-main-color);
        }
      }
    }
  }

  &--fourth-step {
    padding: 0 40px;
    .confirm-data-box {
      background: #EEEEEE;
      border: 2px solid #F8F8F8;
      border-radius: 3px;
      position: relative;
      padding: 20px 10px;
      .remove-video {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #FF7E7E;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease-in-out 0.25s;
        &:hover {
          background: #cb3a3a;
        }
        svg {
          width: 10px;
          path {
            fill: #EEEEEE;
          }
        }
      }
      .edit-info-box {
        width: 30px ;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid var(--auth-main-color);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 10px;
        @include ltr(){
          right: 10px;
        }
        @include rtl() {
          left: 10px;
        }
        transition: all ease-in-out 0.25s;
        svg path {
          transition: all ease-in-out 0.25s;
        }
        &:hover {
          background: var(--auth-main-color);
          svg path {
            stroke: #fff;
          }
        }
      }
    }
    .tutor-basic-info-box {
      padding: 20px;
      display: flex;
      margin-bottom: 20px;
      @include respond-below(md) {
        display: block;
      }
      &--main-info {
        @include ltr(){
          padding-right: 15px;
          margin-right: 15px;
          border-right: 1px solid #9F9F9F;
        }
        @include rtl() {
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px solid #9F9F9F;
        }
        display: flex;
        align-items: center;
        flex: 0 0 410px;
        @include respond-below(lg) {
          display: block;
          flex: 0 0 300px;
        }
        @include respond-below(md) {
          @include ltr(){
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }
          @include rtl() {
            padding-left: 0;
            margin-left: 0;
            border-left: 0;
          }
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid #9F9F9F;
        }
        .tutor-photo {
          flex: 0 0 120px;
          width: 120px;
          margin: 0 auto 20px;
          @include respond-below(lg) {
            flex: 0 0 100px;
          }
          border-radius: 50%;
          img {
            width: 100%;
          }
        }
        .tutor-info-list {
          list-style: none;
          margin: 0;
          flex: 0 0 calc(100% - 120px);
          @include ltr(){
            padding-left: 20px;
          }
          @include rtl() {
            padding-right: 20px;
          }
          @include respond-below(lg) {
            flex: 0 0 calc(100% - 120px);
            @include ltr(){
              padding-left: 0;
            }
            @include rtl() {
              padding-right: 0;
            }
          }

          li {
            display: flex;
            @include respond-below(md) {
              justify-content: center;
            }
            @include respond-below(sm) {
              display: block;
            }
            p {
              margin: 0;
              &.label {
                flex: 0 0 95px;
                @include ltr(){
                  margin-right: 30px;
                }
                @include rtl() {
                  margin-left: 30px;
                }
                font-size: 16px;
                @include respond-below(sm) {
                  margin-bottom: 10px;
                }
              }
              &.info {
                max-width: 100px;
                width: 100%;
              }
              &.info span {
                @include ltr(){
                  margin-right: 15px;
                }
                @include rtl() {
                  margin-left: 15px;
                }
                @include respond-below(sm) {
                  display: none;
                }
              }
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
      &--bio-info {
        display: flex;
        @include respond-below(lg) {
          display: block;
        }
        & > span {
          @include ltr(){
            margin-right: 15px;
          }
          @include rtl() {
            margin-left: 15px;
          }
          @include respond-below(lg){
            display: none;
          }
        }
        p {
          margin: 0;
          &.bio-label {
            @include ltr(){
              margin-right: 10px;
            }
            @include rtl() {
              margin-left: 10px;
            }
            font-size: 16px;
            @include respond-below(lg) {
              @include ltr(){
                margin-right: 0;
              }
              @include rtl() {
                margin-left: 0;
              }
              margin-bottom: 10px;
            }
          }
          &.bio-info {
            max-width: 450px;
            width: 100%;
          }
        }
      }
    }
    .tutor-courses-info-box {
      flex: 0 0 595px;
      @include ltr(){
        margin-right: 20px;
      }
      @include rtl() {
        margin-left: 20px;
      }
      @include respond-below(xl) {
        @include ltr(){
          margin-right: 0;
        }
        @include rtl() {
          margin-left: 0;
        }
        margin-bottom: 30px;
      }
      &--title {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px;
      }
      &--course-name {
        font-size: 15px;
        margin-bottom: 10px;
      }
      &--videos-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: flex;
          align-items: center;
          .video-name {
            font-weight: 500;
            margin: 0;
            @include ltr(){
              margin-right: 15px;
            }
            @include rtl() {
              margin-left: 15px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 13px;
          }
        }
      }
      &--courses-container {
        max-height: 175px;
        overflow: auto;
        display: flex;
        @include respond-below(md) {
         flex-wrap: wrap;
        }
        @include ltr(){
          padding-right: 10px;
          @include respond-below(sm) {
            padding-right: 0;
          }
        }
        @include rtl() {
          padding-left: 10px;
          @include respond-below(sm) {
            padding-left: 0;
          }
        }
        .course-videos {
          @include respond-between(md , xl){
            flex: 0 0 calc(100% / 3);
            @include ltr(){
              margin-right: 0 !important;
            }
            @include rtl() {
              margin-left: 0 !important;
            }
          }

        }
        .course-videos:not(:last-child){
          @include ltr(){
            margin-right: 15px;
          }
          @include rtl() {
            margin-left: 15px;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          @include respond-below(sm) {
            width: unset;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          @include respond-below(sm) {
            background-color: unset;
            border-radius: unset;
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(#9F9F9F, 0.5);
          border-radius: 10px;
          @include respond-below(sm) {
            background-color: unset;
            border-radius: unset;
          }
        }
      }
    }
    .tutor-available-time-box{
      flex: 0 0 calc(100% - 615px);
      &--title {
        font-size: 16px;
        margin-bottom: 20px;
      }
      &--list {
        list-style: none;
        padding: 0;
        margin: 0;
        height: 175px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          @include respond-below(sm) {
            width: unset;
          }
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          @include respond-below(sm) {
            background-color: unset;
            border-radius: unset;
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(#9F9F9F, 0.5);
          border-radius: 10px;
          @include respond-below(sm) {
            background-color: unset;
            border-radius: unset;
          }
        }
        li {
          display: flex;
          @include respond-between(xl, xxl)
          {
            display: block;
            position: relative;
            .remove-video {
              position: absolute;
              top: 0;
              @include ltr() {
                right: 20px;
              }
              @include rtl(){
                left: 20px;
              }
            }
          }
          @include respond-between(sm, md) {
            display: block;
            position: relative;
            .remove-video {
              position: absolute;
              top: 0;
              @include ltr() {
                right: 20px;
              }
              @include rtl(){
                left: 20px;
              }
            }
          }
          @include respond-below(sm) {
            display: block;
            text-align: center;
            .remove-video {
             margin: 10px auto;
            }
          }
          p {
            margin: 0;
            font-weight: 500;
            &.day {
              max-width: 195px;
              width: 100%;
              @include ltr(){
                margin-right: 15px;
              }
              @include rtl() {
                margin-left: 15px;
              }
              @include respond-below(sm) {
                max-width: 100%;
              }
            }
            &.time {
              max-width: 220px;
              width: 100%;
              @include ltr(){
                margin-right: 10px;
              }
              @include rtl() {
                margin-left: 10px;
              }
              @include respond-below(sm) {
                max-width: 100%;
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
/* Start Tutor Complete Profile Model
**************************************/