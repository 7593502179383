/* Start Dashboard Layout
**************************************/
.dashboard {
  display: flex;
  padding: 40px 0;
  align-items: flex-start;
  @include respond-below(md) {
    display: block;
  }
  &--tutor-style {
    --auth-main-color: #{$blue};
  }

  &--menu {
    flex: 0 0 260px;
    background: #fff;
    border: 1px solid #C6C6C5;
    border-radius: 3px;
    list-style: none;
    padding: 0;
    margin: 0;

    @include respond-below(xxl) {
      flex: 0 0 210px;
    }
    @include respond-below(md) {
      border-radius: 0 0 3px 3px;
    }
    &>li:not(:last-child):after{
      content: '';
      display: block;
      max-width: 180px;
      width: 100%;
      height: 0.5px;
      background: #9F9F9F;
      margin: auto;
      @include respond-below(md) {
        max-width: 100%;
      }
    }

    &--item-link {
      font-size: 16px;
      color: #9F9F9F;
      text-decoration: none;
      display: block;
      padding: 20px 40px;
      transition: all ease-in-out 0.25s;
      svg {
        display: inline-block;
        @include ltr() {
          margin-right: 10px;
        }
        @include rtl() {
          margin-left: 10px;
        }
        path {
          transition: all ease-in-out 0.25s;
        }
      }
      &.link-collapse {
        color: var(--auth-main-color);
        position: relative;
        svg path {
          fill: var(--auth-main-color);
        }
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15242' data-name='Path 15242' d='M592.995,120.1l-4.764-4.784a1.055,1.055,0,0,0-1.494,0L581.95,120.1a.969.969,0,0,0-.076,1.433l.175.178a.767.767,0,0,0,1.157.057q1.769-1.764,3.531-3.528a1.055,1.055,0,0,1,1.494,0q1.759,1.764,3.528,3.528a.764.764,0,0,0,1.154-.057l.181-.178a.962.962,0,0,0-.1-1.433Z' transform='translate(-581.59 -115.003)' fill='%230180d8' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          @include ltr() {
            right: 40px;
          }
          @include rtl() {
            left: 40px;
          }

        }
        &.collapsed {
          color: #9F9F9F;
          &:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.781' height='7' viewBox='0 0 11.781 7'%3E%3Cpath id='Path_15241' data-name='Path 15241' d='M592.995,116.91l-4.764,4.784a1.055,1.055,0,0,1-1.494,0l-4.787-4.784a.969.969,0,0,1-.076-1.433l.175-.178a.767.767,0,0,1,1.157-.057q1.769,1.764,3.531,3.528a1.055,1.055,0,0,0,1.494,0q1.759-1.764,3.528-3.528a.764.764,0,0,1,1.154.057l.181.178a.962.962,0,0,1-.1,1.433Z' transform='translate(-581.59 -115.003)' fill='%239f9f9f' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
          }
          svg path {
            fill: #9F9F9F;
          }
        }
        &:hover {
          color: var(--auth-main-color);
          svg path {
            fill: var(--auth-main-color);
          }
        }
      }
      &.active,
      &:hover {
        color: var(--auth-main-color);
        svg path {
          fill: var(--auth-main-color);
        }
      }
    }
    &--sub-menu {
      list-style: none;
      padding: 0;
      margin-bottom: 0;
      &>li:not(:last-child) {
        margin-bottom: 10px;
      }
      li:last-child {
        padding-bottom: 20px;
      }
    }
    &--item-sublink {
      display: block;
      text-decoration: none;
      padding: 5px 0;
      color: #9F9F9F;
      @include ltr(){
        padding-left: 65px;
        padding-right: 40px;
        border-left: 7px solid transparent;
      }
      @include rtl(){
        padding-right: 65px;
        padding-left: 45px;
        border-right: 7px solid transparent;
      }
      transition: all ease-in-out 0.25s;
      &.active {
        background: #F8F8F8;
        border: 1px solid #C6C6C5;
        color: var(--auth-main-color);
        @include ltr() {
          border-left: 7px solid var(--auth-main-color);
        }
        @include rtl() {
          border-right: 7px solid var(--auth-main-color);
        }
      }
      &:hover {
        color: var(--auth-main-color);
      }
    }
    &-toggle-btn {
      background: var(--auth-main-color);
      text-align: center;
      color: #fff;
      padding: 10px 20px;
      border-radius: 3px 3px 0 0;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      transition: all ease-in-out 0.25s;
      display: none;
      &.collapsed {
        border-radius: 3px;
      }
      &:hover {
        background: var(--auth-main-color-hover);
        color: #fff;
      }
      @include respond-below(md) {
        display: block;
      }
    }
    &.collapse {
      @include respond-above(md) {
        display: block;
      }
    }
  }

  &--content {
    flex: 0 0 calc(100% - 280px);
    width: calc(100% - 280px);
    height: fit-content;
    @include ltr() {
      margin-left: 20px;
    }
    @include rtl() {
      margin-right: 20px;
    }
    @include respond-below(md) {
      width: 100%;
    }
    @include respond-below(xxl) {
      flex: 0 0 calc(100% - 230px);
    }
    @include respond-below(md) {
      @include ltr() {
        margin-left: 0;
      }
      @include rtl() {
        margin-right: 0;
      }
      margin-top: 30px;
    }
  }
  &--main-box {
    background: #fff;
    border: 1px solid #C6C6C5;
    border-radius: 3px;
  }
}

/* End Dashboard Layout
**************************************/